import React from "react";
import { graphql } from "gatsby";

// Component imports
import MyHabitsLayout from "../../components/my_habits/MyHabitsLayout";
import PDFViewer from "../../components/common/PDFViewer";
import SEO from "../../components/common/seo";

// CSS imports
import "../../assets/sass/app_page.scss";

export default class MyHabitsPrivacy extends React.Component {

    render() {
        const { publicURL: fileURL, name: fileName } = this.props.data.allFile.edges[0].node
        return (
            <MyHabitsLayout>
              <SEO title="My-Habits Privacy" keywords={[`myhabits`, `my-habits`, 'privacy']} />

                <PDFViewer
                    title="My-Habits Privacy Policy"
                    fileName={fileName}
                    fileURL={fileURL} />
            </MyHabitsLayout>
        );
    }
}

export const query = graphql`
{
	allFile(filter: {extension: {eq: "pdf"}, name: {eq: "My-Habits_Privacy_Policy_Outlined"}}) {
    edges {
      node {
        publicURL
        name
      }
    }
  }
}
`